<template>
  <div class="contant-box-main">
    <div class="contant-header">
      <h6>
        <img src="./../../../public/assets/images/star.svg" alt="" />
        Restock Items
      </h6>
      <ul class="nav nav-tabs align-items-center" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button :class="article ? 'nav-link active' : 'nav-link'" id="articles-tab" data-bs-toggle="tab"
            data-bs-target="#articles" type="button" role="tab" aria-controls="articles" aria-selected="false"
            @click="article = true">
            Articles
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button :class="!article ? 'nav-link active' : 'nav-link'" id="cart-tab" data-bs-toggle="tab"
            data-bs-target="#cart" type="button" role="tab" aria-controls="cart" aria-selected="true"
            @click="article = false">
            Cart composition
          </button>
        </li>
      </ul>
    </div>
    <div class="tab-content h-100 position-relative" id="myTabContent">
      <div class="loader-main" v-if="loadindShow">
        <div class="spinner-border" role="status" style="width: 36px; height: 36px; color: #5e66fa">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div :class="article ? 'tab-pane fade show active' : 'tab-pane fade'" id="articles" role="tabpanel"
        aria-labelledby="articles-tab">
        <div class="contant-details">

          <div class="overflow-auto no-pagination">
            <table class="table table-sm" id="my-table">
              <thead>
                <tr>
                  <th scope="col" v-for="f in fields" v-bind:key="f.id">
                    {{ f.split("_").join(" ") }}
                  </th>
                </tr>
              </thead>
              <tbody v-for="(item, counter) in items" v-bind:key="item.id">
                <tr>
                  <td class="font-light-text">{{ counter + 1 }}</td>
                  <td>{{ item.article_name }}</td>
                  <td class="font-light-text">
                    {{ formatDate(item.purchase_period_start) }} - {{ formatDate(item.purchase_period_end) }}
                  </td>
                  <td align="right">
                    <button @click="toggleRowActive(item.article_sku)"
                      :class="{ 'active': activeRows[item.article_sku] }" class="accordian-arrow">
                      <img src="../../../public/assets/images/arrow-down.svg" alt="Arrow" />
                    </button>
                  </td>
                </tr>
                <tr :class="{ 'highlight': activeRows[item.article_sku] }" class="child-row">
                  <td colspan="6">
                    <h3>Sales Projection</h3>

                    <div class="border-div">
                      <table class="table m-0">
                        <thead>
                          <tr>
                            <th>Period</th>
                            <th>Quantity</th>
                            <th>Total Amount</th>
                          </tr>
                        </thead>
                        <tbody v-for="(data) in item?.salesProjectionData" v-bind:key="data.article_sku">
                          <tr>
                            <td>
                              Last order
                            </td>
                            <td>{{ data.last_order.quantity }}</td>
                            <td>{{ data.last_order.amount }}</td>
                          </tr>
                          <tr>
                            <td>
                              Previous year
                            </td>
                            <td>{{ data.previous_year.quantity }}</td>
                            <td>{{ data.previous_year.amount }}</td>
                          </tr>
                          <tr>
                            <td>
                              Year to date
                            </td>
                            <td>{{ data.year_to_date.quantity }}</td>
                            <td>{{ data.year_to_date.amount }}</td>
                          </tr>
                          <tr>
                            <td>
                              This month last year
                            </td>
                            <td>{{ data.this_month_last_year.quantity }}</td>
                            <td>{{ data.this_month_last_year.amount }}</td>
                          </tr>

                          <tr>
                            <td>
                              <strong>
                                Projected sale this month
                              </strong>
                            </td>
                            <td>
                              <strong>
                                {{ data.projected_sales.quantity }}
                              </strong>
                            </td>
                            <td>
                              <strong>
                                {{ data.projected_sales.amount }}
                              </strong>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div :class="!article ? 'tab-pane fade show active' : 'tab-pane fade'" id="cart" role="tabpanel"
        aria-labelledby="cart-tab">
        <!-- articles table start -->
        <table class="table table-sm likey_buy_table">
          <thead>
            <tr>
              <th></th>
              <th class="id-category" scope="col">Category</th>
              <th scope="col">Total Products</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(data, index) in testData.datasets[0]['data']" v-bind:key="data">
              <td class="category_number">{{ testData.numbers[index] }}</td>
              <td class="category_label">{{ testData.labels[index] }}</td>
              <td class="category_data font-light-text">{{ data }}</td>
            </tr>
          </tbody>
        </table>
        <!-- articles table end -->
        <!-- articles table start -->
        <table class="table table-sm likey_buy_table">
          <thead>
            <tr>
              <th></th>
              <th class="id-category" scope="col">Category</th>
              <th scope="col">Total Products</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(data, index) in testData.datasets[0]['data']" v-bind:key="data">
              <td class="category_number">{{ testData.numbers[index] }}</td>
              <td class="category_label">{{ testData.labels[index] }}</td>
              <td class="category_data font-light-text">{{ data }}</td>
            </tr>
          </tbody>
        </table>
        <!-- articles table end -->
        <!-- category wise count chart start -->
        <div class="pie-table">
          <DoughnutChart :chartData="testData" :options="options" class="canvas-outer" />
          <div>
            <ul class="ul-table">
              <li v-for="(data, index) in testData.datasets[0]['data']" v-bind:key="data">
                <div class="detail-title">
                  <span :style="{
                    'background-color':
                      testData.datasets[0]['backgroundColor'][index],
                  }"></span>
                  <p>{{ testData.labels[index] }}</p>
                </div>
                <span class="percentage-data">{{ Math.round((data * 100) / totalCount) }} %</span>
              </li>
            </ul>
          </div>
        </div>
        <!-- category wise count chart end -->


      </div>
    </div>
  </div>
</template>
<script>
import { DoughnutChart } from "vue-chart-3";
import { Chart, registerables } from "chart.js";
import { mapActions, mapGetters } from "vuex";
// MEMBER_ARTICLES,
import {
  MOST_LIKELY_TO_BUY_PRODUCT,
  ARTICLES_SALES_PROJECTION,
  CATEGORIESVISECOUNT,
} from "@/Core/store/action-types";
Chart.register(...registerables);

export default {
  name: "MostLikelyToBuy",
  components: { DoughnutChart },
  computed: {
    ...mapGetters("customer", ["customerDetails"]),
  },
  data() {
    return {
      activeRows: {},
      article: true,
      params: {
        persist: true,
      },
      fields: ["No.", "Product Name", "	Purchase Period", ""],
      chartData: [],
      totalCount: 0,
      items: [], // main data state
      loadindShow: false,
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
        },
      },
      testData: {
        numbers: [],
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: [
              "#C2CAFF",
              "#9AA2FF",
              "#868EFF",
              "#727AFF",
              "#5E66FA",
            ],
          },
        ],
      },
    };
  },
  methods: {
    ...mapActions("article", [MOST_LIKELY_TO_BUY_PRODUCT, ARTICLES_SALES_PROJECTION]),
    ...mapActions("product", [CATEGORIESVISECOUNT]),
    //get member products data
    InItProductDetails() {
      if (this?.$route?.params?.customerId && this.$route.params.customerId != "") {
        const customerId = this.$route.params.customerId;
        this.loadindShow = true
        this[MOST_LIKELY_TO_BUY_PRODUCT](customerId)
          .then((resp) => {
            this.items = resp;
            console.log("this.items product", this.items)
          })
          .catch((err) => {
            console.log("error=>", err);
          }).finally(() => this.loadindShow = false)
      }
    },
    formatDate(date) {
      const newDate = new Date(date);
      return newDate.toLocaleDateString();
    },
    //get category wise count data
    InItCategoriesViseCount() {
      if (this?.$route?.params?.customerId && this.$route.params.customerId != "") {
        const customerId = this.$route.params.customerId;
        this.loadindShow = true
        this[CATEGORIESVISECOUNT]({ id: customerId })
          .then((res) => {
            1;
            this.testData.numbers = Array(Math.ceil(res.length))
              .fill(0)
              .map((e, i) => i + 1);
            res.map((item) => {
              this.testData.labels.push(item.category__name);
              this.chartData.push(item.total);
              this.totalCount += item.total;
            });
            this.testData.datasets[0].data = this.chartData;
          })
          .catch((err) => {
            console.error(err);
          }).finally(() => this.loadindShow = false)
      }
    },

    articleSalesData(article_sku) {
      try {
        if (article_sku && this?.$route?.params?.customerId && this.$route.params.customerId != "") {
          const customerId = this.$route.params.customerId;
          const params = {
            "article_sku": article_sku,
            "member_id": customerId
          }
          this.loadindShow = true
          this[ARTICLES_SALES_PROJECTION](params)
            .then((resp) => {
              // this.sales = resp;
              console.log("response", resp)
              this.items = this.items.map((c) =>
                c.article_sku === article_sku ? { ...c, salesProjectionData: resp.data } : c
              )
              console.log('api', this.items)
            })
            .catch((err) => {
              console.log("error=>", err);
            }).finally(() => this.loadindShow = false)
        }

      } catch (error) {
        console.error("Error fetching details:", error);
      } finally {
        this.showLoading = false;
      }
    },

    // Toggle row expansion and fetch details
    toggleRowActive(article_sku) {
      console.log('>?>?', article_sku)
      this.activeRows[article_sku] = !this.activeRows[article_sku];
      // call api 
      if (this.activeRows[article_sku]) {
        this.articleSalesData(article_sku);
      }
    },
  },

  mounted() {
    this.InItCategoriesViseCount();
    this.InItProductDetails();
  },
};
</script>
<style scoped>
.contant-box-main {
  background: #fff;
  border: 1px solid #ECECED;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.0509803922);
  border-radius: 12px;
  margin-bottom: 20px;
  padding: 0;
}

.contant-header {
  padding: 20px;
  margin: 0;
  min-height: 74px;
}

.contant-header h6 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.contant-header h6 img {
  margin-top: -3px;
}

.nav-tabs {
  gap: 10px;
  flex-wrap: nowrap;
}

.nav-tabs .nav-item {
  margin-bottom: 0;
}

.nav-tabs li button {
  color: #1A2B3A;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  border: 1px solid rgba(26, 43, 58, 0.1019607843);
  opacity: 0.8;
  border-radius: 5px !important;
  padding: 6px 10px !important;
  white-space: nowrap;
}

.nav-tabs li button.active {
  background: #565DF2;
  color: #fff;
  opacity: 1;
  border-color: #565DF2;
}

table {
  margin: 0;
}

.table> :not(:first-child) {
  border-top: 0;
}

table tr th {
  color: #4F4D55 !important;
  font-size: 12px !important;
  line-height: 20px !important;
  font-weight: 500 !important;
  vertical-align: middle;
  border-bottom: 1px solid #E9EBED !important;
  background: #565DF20F;
  padding: 12px 20px;
}

table tr td {
  padding: 12px 20px;
  color: #4F4D55;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  border-color: #E9EBED;
}

button.accordian-arrow {
  border: 0;
  background: transparent;
  padding: 0;
  margin: 0;
  transform: rotate(-90deg);
}

.accordian-arrow.active {
  transform: none;
}

.child-row {
  display: none;
}

.child-row.highlight {
  display: table-row;
}

.child-row h3 {
  color: #1A2B3A;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
  padding-bottom: 10px;
}

.child-row tr td {
  border: 0;
}

.border-div {
  border: 1px solid #E9EBED;
  border-radius: 12px;
}
</style>