<template>
  <div>
    <div class="vld-parent">
      <loading v-model:active="isLoading" :is-full-page="fullPage" />
    </div>
    <MainMenu />
    <TopBar title="Sales" :author="{ home: 'Home' }" />
    <div class="main-wrp">
      <div class="sales-topbar">
        <div class="row">
          <div class="col-lg-8">
            <div class="dashboard-tabs-card">
              <ul class="nav nav-pills" id="pills-tab" role="tablist">
                <li class="nav-item" @click='changeTab("re-stock")'>
                  <a class="nav-link" :class="{ active: activeTab === 're-stock' }">Re-stock</a>
                </li>

                <li class="nav-item" @click='changeTab("inactive-customers")'>
                  <a class="nav-link" :class="{ active: activeTab === 'inactive-customers' }">Inactive customers</a>
                </li>

                <li class="nav-item" @click='changeTab("no-orders")'>
                  <a class="nav-link" :class="{ active: activeTab === 'no-orders' }">No Orders</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="date-range-picker-box">
              <div class="input-group input-daterange ml-auto">
                <input id="icon-date" type="text" ref="salesDate" class="form-control daterange" readonly
                  name="daterange" :value="selectedDateRange" />
                <img src="../../public/assets/images/calendar.svg" alt="calendar" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="tab-content" id="pills-tabContent">
        <div v-if="activeTab === 're-stock'" class="tab-pane fade show active">
          <RestockTable :selected-date-range="selectedDateRange" :start-date="startDate" :end-date="endDate" />
        </div>

        <div v-if="activeTab === 'inactive-customers'" class="tab-pane fade show active">
          <InActiveCustomerTable :selected-date-range="selectedDateRange" :start-date="startDate" :end-date="endDate" />
        </div>

        <div v-if="activeTab === 'no-orders'" class="tab-pane fade show active">
          <NoOrderTable :selected-date-range="selectedDateRange" :start-date="startDate" :end-date="endDate" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainMenu from "../Menu/MainMenu.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { DATE_FORMAT } from "../Core/helpers/utils";
import TopBar from "../Menu/TopBar.vue";
import InActiveCustomerTable from "./components/InActiveCustomerTable.vue";
import NoOrderTable from "./components/NoOrderTable.vue";
import RestockTable from "./components/RestockTable.vue";

export default {
  name: "Sales",
  components: {
    MainMenu,
    Loading,
    TopBar,
    InActiveCustomerTable,
    NoOrderTable,
    RestockTable
  },
  data() {
    const tabDateRanges = {
      "re-stock": {
        startDate: window.moment().subtract(10, "days").format(DATE_FORMAT),
        endDate: window.moment().format(DATE_FORMAT),
        selectedDateRange:
          window.moment().subtract(10, "days").format(DATE_FORMAT) +
          " - " +
          window.moment().format(DATE_FORMAT),
      },
      "inactive-customers": {
        startDate: window.moment().subtract(2, "years").format(DATE_FORMAT), // Get data from 10 years ago
        endDate: window.moment().subtract(365, "days").format(DATE_FORMAT), // Exclude last year
        selectedDateRange:
          window.moment().subtract(2, "years").format(DATE_FORMAT) +
          " - " +
          window.moment().subtract(365, "days").format(DATE_FORMAT),
      },

      "no-orders": {
        startDate: window.moment().subtract(90, "days").format(DATE_FORMAT),
        endDate: window.moment().format(DATE_FORMAT),
        selectedDateRange:
          window.moment().subtract(90, "days").format(DATE_FORMAT) +
          " - " +
          window.moment().format(DATE_FORMAT),
      },
    };

    const defaultTab = "re-stock";
    const { startDate, endDate, selectedDateRange } = tabDateRanges[defaultTab];
    return {
      activeTab: defaultTab,
      fullPage: true,
      isLoading: true,
      dateFilterParams: {},
      startDate: startDate,
      endDate: endDate,
      selectedDateRange: selectedDateRange,
      tabDateRanges: tabDateRanges,
      predefindedSalesDates: {
        Yesterday: [window.moment().subtract(1, "days"), window.moment()],
        "Last 7 Days": [window.moment().subtract(6, "days"), window.moment()],
        "Last 30 Days": [window.moment().subtract(29, "days"), window.moment()],
        "This Month": [window.moment().startOf("month"), window.moment().endOf("month")],
        "This Year": [window.moment().startOf("year"), window.moment().endOf("year")],
      },
    };
  },
  methods: {
    // Get the current tab when click
    changeTab(tab) {
      this.activeTab = tab;
      const { startDate, endDate, selectedDateRange } = this.tabDateRanges[tab];
      this.startDate = startDate;
      this.endDate = endDate;
      this.selectedDateRange = selectedDateRange;
    },
    // for date change picker
    datechange(startDate, endDate) {
      const formattedStartDate = startDate.format(DATE_FORMAT);
      const formattedEndDate = endDate.format(DATE_FORMAT);

      this.tabDateRanges[this.activeTab] = {
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        selectedDateRange: `${formattedStartDate} - ${formattedEndDate}`,
      };
      this.startDate = formattedStartDate;
      this.endDate = formattedEndDate;
      this.selectedDateRange = `${formattedStartDate} - ${formattedEndDate}`;
    },
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 3000);

    const last10Days = [window.moment().subtract(9, "days"), window.moment()];
    this.tabDateRanges[this.activeTab] = {
      startDate: last10Days[0].format(DATE_FORMAT),
      endDate: last10Days[1].format(DATE_FORMAT),
      selectedDateRange: `${last10Days[0].format(DATE_FORMAT)} - ${last10Days[1].format(
        DATE_FORMAT
      )}`,
    };
    // Initialize the date picker
    this.datepicker = window.$(this.$refs.salesDate);
    let _this = this;
    this.datepicker.daterangepicker(
      {
        autoUpdateInput: false,
        ranges: _this.predefindedSalesDates,
        locale: {
          format: "DD/MM/YYYY",
        },
      },
      this.datechange
    );
  },
};
</script>

<style scoped>
.input-daterange .form-control.daterange {
  cursor: pointer;
}

.nav-item {
  cursor: pointer;
}

.sales-topbar {
  padding-bottom: 23px;
  position: relative;
}

.nav-pills {
  gap: 11px;
  background: transparent !important;
}

.nav-pills li a {
  color: #1a2b3a;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  border: 1px solid #1a2b3a1a;
  opacity: 0.8;
  border-radius: 5px !important;
  padding: 6px 10px !important;
}

.nav-pills li a.active {
  background: #565df2;
  color: #fff;
  opacity: 1;
  border-color: #565df2;
}

.date-range-picker-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.static-display {
  font-size: 14px;
  font-weight: bold;
  color: #1a2b3a;
  padding: 8px 15px;
  /* Add padding similar to the date range input */
  border: 1px solid #ced4da;
  /* Match the border style of the input */
  border-radius: 4px;
  background-color: #fff;
  display: flex;
  align-items: center;
}
</style>
