<template>
  <div>
    <div>
      <CustomModel v-if="deletedresource" :modalId="deleteModalId" :item="deletedresource" :onDelete="deleteOrg"
        :resourseName="'Company'" actionType="Delete"></CustomModel>

      <OrgModal :modalId="editModalId" :orgToEdit="editedResource" :modalType="1" :initOrgData="initOrgData" />

      <!-- <OrgModal :modalId="createModalId" :initOrgData="initOrgData" :modalType="2"/> -->
      <RegistrationModal v-bind:modalId="createModalId" name="Company" selectedRole="Merchant"
        :initFunction="initOrgData" />
      <div class="main-wrp">
        <div class="contant-box-main p-0">
          <div class="data-heading-wrp">
            <div class="data-heading">
              <div class="row">
                <div class="col-lg-6">
                  <!-- search box start -->
                  <div class="search-box-wrp">
                    <input type="text" class="form-control" v-model="search" name=""
                      placeholder="Search by the company name" />
                    <a class="search_icon" href="#"><img src="../../../public/assets/images/search_icon.svg"
                        alt="" /></a>
                  </div>
                </div>
                <div class="col-lg-6 text-right">
                  <!-- create button start -->
                  <div class="data-heading-btn">
                    <button @click="showCreateDialog">+ create new company</button>
                  </div>
                  <!-- create button end -->
                </div>
              </div>
            </div>
          </div>
          <!-- org table start -->
          <div class="table-wrp overflow-auto position-relative">
            <div class="loader-main" v-if="loadindShow">
              <div class="spinner-border" role="status" style="width: 36px; height: 36px; color: #5e66fa">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
            <table class="table my-table-wrp table-sm" id="my-table">
              <thead>
                <tr>
                  <th scope="col" v-for="f in fields" v-bind:key="f.id">
                    <div class="table-head">{{ f.split("_").join(" ") }}</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="org in orgs.results" v-bind:key="org.id" class="active-row">
                  <td width="5%" class="font-light-text">{{ org.id }}</td>

                  <td width="20%" class="font-text">
                    <router-link :to="{
                      name: `OrgDetails`,
                      params: { id: org.id },
                    }">{{ org.name }}</router-link>
                  </td>

                  <td width="20%" class="font-light-text">
                    {{ org.billing_address }}
                  </td>
                  <td width="10%" class="font-light-text">
                    {{ org.postal_id }}
                  </td>
                  <td width="15%" class="font-light-text">{{ org.city }}</td>
                  <td width="12%" class="font-light-text">{{ org.country }}</td>
                  <td width="11%" class="font-light-text">
                    {{ org.vat_no || "-" }}
                  </td>
                  <td class="btn-align" width="10%">
                    <div class="btn-grp">
                      <router-link :to="{
                        name: `OrgDetails`,
                        params: { id: org.id },
                      }">
                        <button class="action-btns ms-2 icon-effect" title="See Campaign Details">
                          <!-- <DisableEyeIcon /> -->
                          <!-- <EyeIcon v-on:click="editCampaign(campaign)" /> -->
                          <img src="@/../public/assets/images/eye-blue.svg" alt="Logo" />
                        </button>
                      </router-link>
                      <button class="action-btns ms-2 icon-effect" @click="showEditDialog(org)">
                        <PenIcon />
                      </button>
                      <button title="Generate API Keys" class="action-btns ms-2 icon-effect" @click="getAPIKeys(org)">
                        <InventoryStockIcon />
                      </button>
                      <!-- <button
                        class="action-btns ms-2 icon-effect"
                        @click="showDeleteModel(org)"
                      >
                        <DeleteIcon />
                      </button> -->
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <Pagination :page="page" :totalPages="totalPages" name="Companies" :count="orgs.count ? orgs.count : 0"
              :incrementpage="incrementpage" :decrementpage="decrementpage" :setpage="setpage" :perpage="10" />
          </div>
          <!-- org table end -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import DeleteIcon from "../../icons/cancel";
import PenIcon from "../../icons/pen.vue";
import Pagination from "../../Core/Pagination";
import InventoryStockIcon from "../../icons/inventory-stock.vue";
import OrgModal from "./OrgModal";
import { mapActions } from "vuex";
import { subject } from "@casl/ability";
import { RESOURCE_NAME } from "../org.vars";
import CustomModel from "../../Core/CustomModel.vue";
import RegistrationModal from "../../User/components/RegistrationModal.vue";
import { API_URL } from "@/Core/helpers/utils";
import { LIST_ORGS, DELETE_ORG, GENERATE_API_KEY } from "@/Core/store/action-types";
export default {
  name: "OrgTable",
  components: {
    // DeleteIcon,
    PenIcon,
    Pagination,
    OrgModal,
    CustomModel,
    RegistrationModal,
    InventoryStockIcon
  },
  data() {
    return {
      fields: [
        "ID",
        "Name",
        "Billing Address",
        "Postal Code",
        "City",
        "Country",
        "Vat No",
        "",
      ],
      orgs: [],
      params: { persist: true },
      search: "",
      page: 1,
      totalPages: [],
      createModalId: "createDialog",
      deleteModalId: "deleteDialog",
      editModalId: "editDialog",
      editedResource: {},
      deletedresource: {},
      duplicateResource: {},
      createpermission: this.$ability.can("create", subject(RESOURCE_NAME, {})),
      loadindShow: false
    };
  },
  methods: {
    ...mapActions("org", [LIST_ORGS, DELETE_ORG, GENERATE_API_KEY]),
    //get org data
    initOrgData(pageNumber = null) {
      pageNumber ? (this.page = pageNumber) : null;
      let options = {
        q: this.search.length < 3 ? null : this.search,
        page: this.page,
      };
      this.loadindShow = true
      this[LIST_ORGS](options).then((res) => {
        this.orgs = res;
        this.totalPages = Array(Math.ceil(res.count / 10))
          .fill(0)
          .map((e, i) => i + 1);
      })
        .finally(() => this.loadindShow = false)

    },
    //open craete org model
    showCreateDialog() {
      window.$(`#${this.createModalId}`).modal("toggle");
    },
    showEditDialog(org) {
      this.editedResource = org;
      window.$(`#${this.editModalId}`).modal("toggle");
    },
    showDeleteModel(item) {
      this.deletedresource = item;
      window.$(`#${this.deleteModalId}`).modal("toggle");
    },

    getAPIKeys(org) {
      // might run api here for generating api key
      navigator.clipboard.writeText(`${API_URL}/api/v1/customer_remote_api/?org_id=${org.id}&page_size=5&page=1`);
      this.$alertify.notify(
        `API keys for ${org.name} is copied to your clipboard.`,
        "success",
        5
      );
    },
    async deleteOrg() {
      window.$(`#${this.deleteModalId}`).modal("hide");
      let hasPermission = await this.$ability.can(
        "delete",
        subject(RESOURCE_NAME, this.deletedresource)
      );
      if (hasPermission) {
        this[DELETE_ORG](this.deletedresource.id)
          .then(() => {
            this.initOrgData(1);
            this.$alertify.notify(
              `${this.deletedresource.name} successfully removed.`,
              "success",
              3
            );
          })
          .catch(() => {
            this.$alertify.notify("Action Forbidden", "error", 3);
          });
      } else {
        this.$alertify.notify("Action Forbidden", "error", 3);
      }
    },
    //go to next page
    incrementpage() {
      this.page = this.page + 1;
      this.initOrgData();
    },
    //go to previous page
    decrementpage() {
      this.page = this.page - 1;
      this.initOrgData();
    },
    // go to perticular page
    setpage(page) {
      this.page = page;
      this.initOrgData();
    },
  },
  mounted() {
    this.initOrgData();
  },
  watch: {
    search() {
      if (this.search.length >= 3 || this.search.length == 0) {
        this.page = 1;
        this.initOrgData();
      }
    },
  },
};
</script>

<style scoped>
.contant-box-main {
  padding: 0;
  border: 1px solid #ECECED;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.0509803922);
  border-radius: 12px;
}

.data-heading-wrp {
  display: block;
  padding: 20px;
}

.search-box-wrp {
  position: relative;
  min-width: 190px;
  display: inline-block;
  margin-right: 0px;
  width: 190px;
}

.search-box-wrp input {
  background: #F5F7F9;
  border-radius: 10px;
  border: 0;
  color: #242220;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.4px;
  padding: 9px 12px 9px 40px;
}

.search-box-wrp input:focus,
.search-box-wrp input:focus-visible {
  outline: none;
  box-shadow: none;
}

.search_icon {
  position: absolute;
  top: 5px;
  left: 12px;
}

.data-heading-btn {
  display: flex;
  justify-content: flex-end;
}

.data-heading-wrp .data-heading-btn button {
  background: #565DF2;
  border-radius: 5px;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  border: 0;
  color: #fff;
  font-weight: 400;
  padding: 8px 10px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  gap: 6px;
  font-family: "Outfit", sans-serif !important;
}

.table-wrp .table thead tr th {
  color: #4F4D55 !important;
  font-size: 12px !important;
  line-height: 20px !important;
  font-weight: 500 !important;
  vertical-align: middle;
  border-bottom: 1px solid #E9EBED !important;
  background: rgba(86, 93, 242, 0.0588235294);
  padding: 12px 20px;
}

.table-wrp .table thead tr th .table-head {
  color: #4F4D55 !important;
  font-size: 12px !important;
  line-height: 20px !important;
  font-weight: 500 !important;
  padding: 0;
}

.table-wrp .table thead tr th span {
  margin-left: 12px;
  cursor: pointer;
}

.table-wrp .table thead tr th span span {
  margin-left: 0px;
  font-size: 14px;
  margin-right: 2px;
}

.table-wrp .table tbody tr td {
  padding: 12px 20px;
  color: #4F4D55;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  border-color: #E9EBED;
}

.table-wrp .table .main-button--table td {
  padding: 0;
  border: 0;
  width: auto;
}

.table> :not(:first-child) {
  border-top: 0;
}

.table-wrp .table .eye-view {
  background: transparent;
  border: 0;
  border-radius: 0;
  width: auto;
  height: auto;
  margin: 0 !important;
  padding: 0;
}

table.main-button--table {
  margin: 0 auto;
  text-align: center;
  width: auto;
}

.table-wrp .table thead tr th:last-child {
  text-align: center;
}

.table-wrp table tr:hover {
  background: transparent;
}

nav.main-pagination {
  padding: 20px;
}

.loader-main {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto !important;
  text-align: center;
  background-color: rgb(255 255 255 / 50%);
  width: 100px;
  transform: translateY(-50%);
  top: 50%;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-wrp .table .icon-effect {
  background: transparent;
  border: 0;
  border-radius: 0;
  width: auto;
  height: auto;
  margin: 0 !important;
  padding: 0;
}

.btn-grp {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7%;
  margin-right: 10px;
}
</style>