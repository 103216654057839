<template>
  <!-- Edit user modal window -->
  <div class="modal fade" :id="modalId" tabindex="-1" role="dialog" aria-labelledby="editDialogLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="editDialogLabel">Edit Article</h5>
          <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ArticleForm ref="child" v-on:form-change="onFormChange" :onSubmit="onSubmit" :isEditMode="true"
          :initData="articleToEdit" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import ArticleForm from "./ArticleForm.vue";
import { UPDATE_ARTICLES, CREATE_PRODUCT } from "@/Core/store/action-types";

export default {
  name: "EditProduct",
  components: {
    ArticleForm,
  },
  props: {
    modalId: {
      required: true,
      type: String,
    },
    articleToEdit: {
      required: true,
      type: Object,
    },
  },

  methods: {
    ...mapActions("article", [UPDATE_ARTICLES]),
    ...mapActions("product", [CREATE_PRODUCT]),

    onSubmit(values, actions) {

      console.log(values, actions, this.articleToEdit.id);
      values.id = this.articleToEdit.id;
      if (values.product === undefined) {
        console.log('if /?')
        this.$refs.child.$refs.productForm.validate().then((result) => {
          // this will create new product and then submit the form 
          if (result.valid) {
            this[CREATE_PRODUCT](this.$refs.child.product_formvalues).then((response) => {
              values.product = response.id
              this[UPDATE_ARTICLES](values)
                .then(() => {
                  this.$parent.initArticles(1)
                  window.$(`#${this.modalId}`).modal("hide");
                  this.$alertify.notify(
                    "article successfully updated.",
                    "success",
                    3
                  );
                  actions.resetForm();
                })
                .catch((e) => {
                  this.$refs.child.formValues.product = response.id
                  console.log(e.message);
                  this.errMsg = e.message
                  actions.setFieldError('detail', e.message || 'something went wrong');
                }).finally(() => this.$refs.child.fetchProducts())
            })
              .catch((e) => {
                console.log(e.message);
                this.errMsg = e.message
                this.$refs.child.$refs.productForm.setFieldError('detail', e.message || 'something went wrong');
              })
          }
          else {
            // If validation fails, still proceed with updating the article (without creating a product)
            console.log('Product form validation failed, but updating article anyway.');
            this[UPDATE_ARTICLES](values)
              .then(() => {
                window.$(`#${this.modalId}`).modal("hide");
                this.$alertify.notify("article successfully updated.", "success", 3);
                actions.resetForm();
                this.$parent.initArticles(1);
              })
              .catch((e) => {
                this.errMsg = e.message;
                actions.setFieldError('detail', e.message || 'something went wrong');
              });
          }
        })
      }
      else {
        console.log('else /')
        this[UPDATE_ARTICLES](values)
          .then(() => {
            window.$(`#${this.modalId}`).modal("hide");
            this.$alertify.notify(
              "article successfully updated.",
              "success",
              3
            );
            actions.resetForm();
            this.$parent.initArticles(1);
          })
          .catch((e) => {
            this.errMsg = e.message;
            actions.setFieldError('detail', e.message || 'something went wrong');
          });
      }
    },

    onFormChange(form) {
      this.formValues = form;
    },
  },

  data() {
    return {
      formValues: {},
    };
  },
};
</script>