<template>
  <div>
    <div class="vld-parent">
      <loading v-model:active="isLoading" :is-full-page="fullPage" />
    </div>
    <MainMenu />
    <TopBar title="Dashboard" :author="{
      home: 'Home',
      cart: 'Dashboard',
    }" />
    <div class="main-wrp">
      <div class="dashboard-block-section">
        <div class="carousel-container">
          <!-- Left Arrow -->
          <div class="carousel-arrow carousel-arrow-left" @click="prevSlide">
            <i class="fas fa-chevron-left"></i>
          </div>

          <!-- Slides -->
          <div class="carousel-slides" ref="slidesContainer">
            <div class="carousel-slide" v-for="(slide, slideIndex) in slides" :key="slideIndex"
              :class="{ 'active': currentSlide === slideIndex }"
              :style="{ transform: `translateX(${-currentSlide * 100}%)` }">
              <div class="row">
                <div class="col" v-for="(box, boxIndex) in slide" :key="boxIndex">
                  <div class="dashboard-block-box">
                    <div class="d-flex align-items-center">
                      <img class="mr-3" :src="getImagePath(box.icon)" alt="Logo" />
                      <p class="m-0">{{ box.title }}</p>
                    </div>
                    <span class='value-style' v-if="box.value !== undefined">
                      <sub v-if="box.currency">{{ default_currency }}</sub>
                      {{ formatBoxValue(box) }}
                    </span>

                    <!-- Split into two separate blocks -->
                    <div class="bottom__title">
                      <!-- Difference indicators (arrows and percentage) -->
                      <template v-if="box.difference !== undefined">
                        <DownArrow v-if="box.difference < 0" />
                        <UpArrow v-else />
                        <span :class="box.difference < 0 ? 'red-text' : 'green-text'">{{ box.difference }}%</span>
                      </template>

                      <!-- Date reference - now independent -->
                      <span v-if="box.dateReference" class="date-reference">
                        <span class="gray-text">{{ box.dateText }} {{ box.dateReference() }}</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Right Arrow -->
          <div class="carousel-arrow carousel-arrow-right" @click="nextSlide">
            <i class="fas fa-chevron-right"></i>
          </div>
          <!-- Dots Indicators -->
          <div class="carousel-dots">
            <span v-for="(slide, index) in slides" :key="index" class="carousel-dot"
              :class="{ 'active': currentSlide === index }" @click="goToSlide(index)"></span>
          </div>
        </div>
      </div>


      <!-- articles -->
      <div class="dashboard-tabs-table-section">
        <div class="row">
          <div class="col-lg-6 mb-3">
            <div class="dashboard-tabs-card">
              <h2>Customers</h2>
              <ul class="nav nav-pills" id="pills-tab" role="tablist">
                <li class="nav-item">
                  <a class="nav-link active" id="restock-now-tab" data-toggle="pill" href="#restock-now" role="tab"
                    aria-controls="restock-now" aria-selected="true">Restock</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="most-valuable-tab" data-toggle="pill" href="#most-valuable" role="tab"
                    aria-controls="most-valuable" aria-selected="false">Most Valuable</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="most-re-occuring-tab" data-toggle="pill" href="#most-re-occuring" role="tab"
                    aria-controls="most-re-occuring" aria-selected="false">Most Re-occuring</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="churn-potential-tab" data-toggle="pill" href="#churn-potential" role="tab"
                    aria-controls="churn-potential" aria-selected="false">Churn Potential</a>
                </li>
              </ul>
              <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="restock-now" role="tabpanel"
                  aria-labelledby="restock-now-tab">
                  <div class="table-contant-wrp">
                    <CustomerNeedRestock />
                  </div>
                </div>
                <div class="tab-pane fade" id="most-valuable" role="tabpanel" aria-labelledby="most-valuable-tab">
                  <div class="table-contant-wrp">
                    <MostValuableCustomersCard />
                  </div>
                </div>
                <div class="tab-pane fade" id="most-re-occuring" role="tabpanel" aria-labelledby="most-re-occuring-tab">
                  <div class="table-contant-wrp">
                    <ReoccuringCustomersCard />
                  </div>
                </div>
                <div class="tab-pane fade" id="churn-potential" role="tabpanel" aria-labelledby="churn-potential-tab">
                  <div class="table-contant-wrp">
                    <ChurnPotentialCutomers />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 mb-3">
            <div class="dashboard-tabs-card">
              <h2>Articles</h2>
              <ul class="nav nav-pills" id="pills-tab" role="tablist">
                <li class="nav-item">
                  <a class="nav-link active" id="top-selling-tab" data-toggle="pill" href="#top-selling" role="tab"
                    aria-controls="top-selling" aria-selected="true">Top Selling</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="top-trending-tab" data-toggle="pill" href="#top-trending" role="tab"
                    aria-controls="top-trending" aria-selected="false">Top Trending</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="re-stocking-national-tab" data-toggle="pill" href="#re-stocking-national"
                    role="tab" aria-controls="re-stocking-national" aria-selected="false">Re-stocking (National)</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="re-stocking-central-tab" data-toggle="pill" href="#re-stocking-central"
                    role="tab" aria-controls="re-stocking-central" aria-selected="false">Re-stocking (Central)</a>
                </li>
              </ul>
              <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="top-selling" role="tabpanel"
                  aria-labelledby="top-selling-tab">
                  <div class="table-contant-wrp">
                    <TopSellingArticles />
                  </div>
                </div>
                <div class="tab-pane fade" id="top-trending" role="tabpanel" aria-labelledby="top-trending-tab">
                  <div class="table-contant-wrp">
                    <TopTrendingArticles />
                  </div>
                </div>
                <div class="tab-pane fade" id="re-stocking-national" role="tabpanel"
                  aria-labelledby="re-stocking-national-tab">
                  <div class="table-contant-wrp">
                    <ArticleRestockingNationalWarehouse />
                  </div>
                </div>
                <div class="tab-pane fade" id="re-stocking-central" role="tabpanel"
                  aria-labelledby="re-stocking-central-tab">
                  <div class="table-contant-wrp">
                    <ArticleRestockingCentralWarehouse />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Rest of the original content remains the same -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  LIST_ARTICLES,
  TRENDING_ARTICLES,
  LIST_AUDIENCE,
  GET_METRICS,
  GET_RECOMMENDATIONS_STATS
} from "@/Core/store/action-types";
import TopBar from "../Menu/TopBar.vue";
import MainMenu from "../Menu/MainMenu.vue";
import UpArrow from "../icons/upArrow.vue";
import DownArrow from "../icons/downArrow.vue";
import CustomerNeedRestock from "./components/CustomerNeedRestock.vue";
import ChurnPotentialCutomers from "./components/ChurnPotentialCutomers.vue";
import ArticleRestockingCentralWarehouse from "./components/ArticleRestockingCentralWarehouse.vue";
import ArticleRestockingNationalWarehouse from "./components/ArticleRestockingNationalWarehouse.vue";
import MostValuableCustomersCard from "./components/MostValuableCustomersCard.vue";
import ReoccuringCustomersCard from "./components/ReoccuringCustomersCard.vue";
import TopSellingArticles from "./components/TopSellingArticles.vue";
import TopTrendingArticles from "./components/TopTrendingArticles.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "Home",
  components: {
    TopBar,
    UpArrow,
    DownArrow,
    CustomerNeedRestock,
    ChurnPotentialCutomers,
    ArticleRestockingCentralWarehouse,
    ArticleRestockingNationalWarehouse,
    MostValuableCustomersCard,
    ReoccuringCustomersCard,
    TopSellingArticles,
    TopTrendingArticles,
    MainMenu,
    Loading,
  },
  data() {
    return {
      currentMonth: '',
      previousMonth: '',
      fullPage: true,
      isLoading: true,
      currentSlide: 0,
      sliding: false,
      recommendationsStats: {},
      currentMonthStats: {},
      prevMonthStats: {},
      generalMetrics: {
        totalOrders: 0,
        totalMembers: 0,
        totalProducts: 0,
        totalArticles: 0,
        get_avg_order_value: {},
        get_avg_clv_value: {},
        get_median_clv_value: {},
      },
      dateFilterParams: {},
      articles: {},
      audiences: {},
      autoSlideInterval: null,
    };
  },
  computed: {
    ...mapGetters("org", ["default_clv_year", "default_currency"]),

    additionalMetrics() {
      return [
        {
          title: "Customer Recommendation",
          icon: "active-customers.svg",
          value: `${this.currentMonthStats?.possible_total_sales_amount?.toFixed(0)} & ${this.currentMonthStats?.customer_recommendation_count?.toFixed(0)}`,
          dateReference: () => (this.currentMonthStats?.month),  // customer recommendation this month 
          currency: true,
          dateText: "In"
        },
        {
          title: "Recommendation Sales",
          icon: "order.svg",
          value: `${this.currentMonthStats?.actual_order_amount_of_recommendation?.toFixed(0)} of ${this.currentMonthStats?.possible_total_sales_amount?.toFixed(0)}` || 0,
          currency: true,
          difference: this.calculateDifference(this.currentMonthStats?.actual_order_amount_of_recommendation, this.currentMonthStats?.possible_total_sales_amount),
          dateReference: () => (this.currentMonthStats?.start_date), // from starting date 
          dateText: "Since"
        },
        {
          title: "Items Sold on Recommendation",
          icon: "average.svg",
          value: `${this.currentMonthStats?.actual_order_qty_of_recommendation?.toFixed(0)} of ${this.currentMonthStats?.possible_total_sales_count?.toFixed(0)}` || 0,
          difference: this.calculateDifference(this.currentMonthStats?.actual_order_qty_of_recommendation, this.currentMonthStats?.possible_total_sales_count),
          dateReference: () => (this.currentMonthStats?.start_date),
          dateText: "Since"
        },
        {
          title: "Customer Purchases Made",
          icon: "articles.svg",
          value: `${this.prevMonthStats?.actual_order_qty_of_recommendation?.toFixed(0)} of ${this.prevMonthStats?.possible_total_sales_count?.toFixed(0)}` || 0,
          difference: this.calculateDifference(this.prevMonthStats?.actual_order_qty_of_recommendation, this.prevMonthStats?.possible_total_sales_count),
          dateReference: () => (this.prevMonthStats?.month),
          dateText: "In"
        },
        {
          title: "Value Sold vs Estimation",
          icon: "median.svg",
          value: `${this.prevMonthStats?.actual_order_amount_of_recommendation?.toFixed(0)} of ${this.prevMonthStats?.possible_total_sales_amount?.toFixed(0)}` || 0,
          currency: true,
          difference: this.calculateDifference(this.prevMonthStats?.actual_order_amount_of_recommendation, this.prevMonthStats?.possible_total_sales_amount),
          dateReference: () => (this.prevMonthStats?.month),
          dateText: "In"
        }
      ];
    },

    slides() {
      // First slide with original metrics
      const slide1 = [
        {
          title: "Active Customers",
          icon: "active-customers.svg",
          value: this.generalMetrics.totalMembers
        },
        {
          title: "Articles",
          icon: "articles.svg",
          value: this.generalMetrics.totalArticles
        },
        {
          title: "Average CLV",
          icon: "average.svg",
          value: this.generalMetrics?.get_avg_clv_value?.avg_clv_value?.toFixed(0) * this.default_clv_year || 0,
          currency: true,
          difference: this.generalMetrics?.get_avg_clv_value?.diffrence?.toFixed(2) || 0,
          dateReference: () => this.last_month_last_date()
        },
        {
          title: "Median CLV",
          icon: "median.svg",
          value: this.generalMetrics?.get_median_clv_value?.median_clv_value?.toFixed(0) * this.default_clv_year || 0,
          currency: true,
          difference: this.generalMetrics.get_median_clv_value?.diffrence?.toFixed(2) || 0,
          dateReference: () => this.last_month_last_date()
        },
        {
          title: "Average Order Value",
          icon: "order.svg",
          value: this.generalMetrics?.get_avg_order_value?.avg_receipt_value?.toFixed(0) || 0,
          currency: true,
          difference: this.generalMetrics.get_avg_order_value?.diffrence?.toFixed(2) || 0,
          dateReference: () => this.one_month_ago_date()
        }
      ];

      const additionalSlides = [];
      for (let i = 0; i < this.additionalMetrics.length; i += 5) {
        additionalSlides.push(this.additionalMetrics.slice(i, i + 5));
      }

      // Return all slides with first slide being the original metrics
      return [...additionalSlides, slide1];
    }
  },
  methods: {
    ...mapActions("article", [LIST_ARTICLES, TRENDING_ARTICLES]),
    ...mapActions("metrics", [GET_METRICS]),
    ...mapActions("audience", [LIST_AUDIENCE]),
    ...mapActions("org", [GET_RECOMMENDATIONS_STATS]),

    getImagePath(iconName) {
      try {
        return require(`@/assets/images/${iconName}`);
      } catch (e) {
        return `/assets/images/${iconName}`;
      }
    },

    // Carousel navigation methods
    nextSlide() {
      if (!this.sliding) {
        this.sliding = true;
        if (this.currentSlide < this.slides.length - 1) {
          this.currentSlide++;
        } else {
          this.currentSlide = 0; // Loop back to first slide
        }
        setTimeout(() => {
          this.sliding = false;
        }, 600);
      }
    },

    prevSlide() {
      if (!this.sliding) {
        this.sliding = true;
        if (this.currentSlide > 0) {
          this.currentSlide--;
        } else {
          this.currentSlide = this.slides.length - 1; // Loop to last slide
        }
        setTimeout(() => {
          this.sliding = false;
        }, 600);
      }
    },

    goToSlide(index) {
      if (!this.sliding && index !== this.currentSlide) {
        this.sliding = true;
        this.currentSlide = index;
        setTimeout(() => {
          this.sliding = false;
        }, 600);
      }
    },

    // Helper methods for formatting
    formatBoxValue(box) {
      if (box.currency) {
        return this.formatNumberWithSpaces(box.value);
      }
      return box.value;
    },

    formatDifference(value) {
      return value?.toFixed(2) || 0;
    },

    async initMetrics() {
      let params = {
        currency: this.default_currency,
      };
      await this[GET_METRICS](params).then((res) => {
        this.generalMetrics = res;
      });
    },
    getMonths() {
      const months = [
        'january', 'february', 'march', 'april', 'may', 'june',
        'july', 'august', 'september', 'october', 'november', 'december'
      ];

      const date = new Date();
      const currentMonthIndex = date.getMonth(); 

      // Get current month name
      this.currentMonth = months[currentMonthIndex];

      // Get previous month name (with January wrapping back to December)
      const previousMonthIndex = currentMonthIndex === 0 ? 11 : currentMonthIndex - 1;
      this.previousMonth = months[previousMonthIndex];
    },

    async initRecommendations() {
      this.isLoading = true;
      const userProfile = JSON.parse(localStorage.getItem('userProfile'));
      const org_id = userProfile.organization.id;
      await this.$store.dispatch('org/GET_RECOMMENDATIONS_STATS', org_id)
        .then(data => {
          this.recommendationsStats = data;

          this.currentMonthStats = data.this_year_projections[this.currentMonth] || {};
          this.prevMonthStats = data.this_year_projections[this.previousMonth] || {};

          this.isLoading = false;
        })
        .catch(error => {
          console.error('Failed to fetch recommendations stats:', error);
          this.isLoading = false;
        });
    },

    async getArticles() {
      let params = {};
      params.order = "-sold_items";
      params.limit = 3;
      params.direction = "up";
      await this[LIST_ARTICLES]({ params: params, persist: true }).then((res) => {
        this.articles = res.results;
      });
    },

    async getAudiences() {
      let params = {};
      params.limit = 3;
      await this[LIST_AUDIENCE]({ params: params, persist: true }).then((res) => {
        this.audiences = res;
      });
    },

    formatNumberWithSpaces(number) {
      if (number === undefined || number === null) return '0';
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },

    calculateDifference(actualData, projectionData) {
      const actual = Number(actualData);
      const projection = Number(projectionData);

      if (isNaN(actual) || isNaN(projection) || projection === 0) {
        return "0.00";
      }

      const difference = (actual / projection) * 100;
      return difference.toFixed(2);
    },


    last_month_last_date() {
      var d = new Date(); // current date
      d.setDate(1); // going to 1st of the month
      d.setHours(-1); // going to last hour before this date even started.
      return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
    },

    one_month_ago_date() {
      var d = new Date(new Date().setDate(new Date().getDate() - 30));
      return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
    },
  },
  async mounted() {
    // First load recommendations and metrics
    await this.initRecommendations();
    await this.initMetrics();

    // Then set isLoading to false after a short delay
    setTimeout(() => {
      this.isLoading = false;
    }, 1000);

  },
  created() {
    // Initialize the month values as soon as the component is created
    this.getMonths();
  },
};
</script>

<style>
.second-row-tab {
  box-shadow: 10px 5px 5px #bfbfc1 !important;
}

/* FontAwesome icon styles */
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css');
</style>

<style scoped>
/* Carousel specific styles */
.carousel-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-bottom: 12px;
}

.carousel-slides {
  display: flex;
  width: 100%;
  overflow: hidden;
}

.carousel-slide {
  flex: 0 0 100%;
  transition: transform 0.6s ease;
}

.carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 35px;
  height: 35px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 10;
  transition: all 0.3s ease;
}

.carousel-arrow:hover {
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
}

.carousel-arrow-left {
  left: 2px;
}

.carousel-arrow-right {
  right: 2px;
}

.carousel-dots {
  display: flex;
  justify-content: center;
  margin-bottom: 2px;
}

.carousel-dot {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #cacbcd;
  margin: 0 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.carousel-dot.active {
  background-color: #5f66fa;
  transform: scale(1.2);
}

.value-style {
  font-size: 18px !important;
}

/* Override for the row inside the carousel to ensure proper spacing */
.carousel-slide .row {
  display: flex;
  justify-content: center;
  width: 95%;
  /* background-color: gray; */
  margin-left: 36px;
  /* padding-top: 12px; */
}

/* Original styles */
.dashboard-bottom-section .dts-row-bottom {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
}

.dashboard-bottom-section .dts-row-bottom .dts-box-outer {
  flex: 1 1 33%;
  padding: 10px;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .dashboard-bottom-section .dts-row-bottom .dts-box-outer {
    flex: 1 1 100%;
  }

  .carousel-arrow {
    width: 20px;
    height: 20px;
  }
}

.dashboard-bottom-section {
  padding-bottom: 0px;
}

.dashboard-bottom-section .dts-row .dts-box-outer {
  padding: 0 10px;
  width: 33.33%;
  flex: 0 0 33.33%;
}

.dashboard-bottom-section .dts-row .dts-box-outer .dts-box {
  height: 100%;
}

.dts-row .dts-box-outer .dts-box {
  box-shadow: 10px 5px 5px #bfbfc1 !important;
}

.dashboard-bottom-section .dts-row .dts-box-outer .dts-box h6 {
  font-size: 22px !important;
}

.bottom__title span {
  font-size: 15px !important;
  margin-bottom: 0 !important;
}

.bottom__title span:nth-child(2) {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

select#adminfilter {
  border-radius: 3px;
  padding: 10px 10px 10px 10px;
  border: 1px solid #cacbcd;
  border-radius: 4px;
  font-weight: 300;
}

select#adminfilter:hover {
  border: 1px solid #000;
}

.view-more-btn {
  color: #5f66fa !important;
  font-weight: 500;
  cursor: pointer;
}

.customer-list-box {
  height: 100%;
  background: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.campaign-box {
  background-color: #5e66fa !important;
  color: #f4be55 !important;
}

.first-row-box {
  display: flex;
  justify-content: space-between;
}

.customer-box {
  background: #30d2dc !important;
}

.article-box {
  background-color: #f4be55 !important;
}

.campaign-box h6,
.campaign-box span,
.customer-box h6,
.customer-box span,
.article-box h6,
.article-box span {
  color: #fff !important;
}

.box-icon {
  margin-top: 20%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
</style>