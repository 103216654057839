<template>
    <div>
        <BarChart :chartData="chartData" :chartOptions="chartOptions" class="canvas-outer" />
    </div>
</template>

<script>
import { defineComponent } from "vue";
import { Chart, registerables } from "chart.js";
import { BarChart } from "vue-chart-3";

// globally set the properties for the chart
Chart.defaults.font.family = "Poppins";
Chart.defaults.font.size = 15;
Chart.defaults.font.weight = 400;
Chart.defaults.plugins.legend.position = "bottom";
Chart.defaults.plugins.legend.labels.boxWidth = 20;
Chart.defaults.plugins.legend.labels.padding = 30;

Chart.register(...registerables);

export default defineComponent({
    props: {
        chartData: {
            type: Object,
            required: true
        }
    },
    components: {
        BarChart,
    },
    data() {
        return {
            chartOptions: {
                responsive: true,
                scales: {
                    x: {
                        stacked: false,
                    },
                    y: {
                        stacked: false,
                    },
                }
            }
        };
    }
});
</script>
