<template>
  <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }" class="form-horizontal" action="#">
    <div class="modal-body">

      <div v-if="!isFileUploadMode">
        <div class="row">
          <div class="col-lg-12">
            <div class="form-group" :class="{ 'has-error': errors.number }">
              <label for="number">
                Article Number<span class="required-field text-danger">*</span></label>
              <Field as="input" type="text" class="form-control" name="sku" placeholder="Enter article unique number"
                v-model="formValues.number" />
              <div class="form-control-feedback">{{ errors.number }}</div>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="form-group" :class="{ 'has-error': errors.name }">
              <label for="name">Article Name<span class="required-field text-danger">*</span></label>
              <Field as="input" type="text" class="form-control" name="name" placeholder="Enter product name"
                v-model="formValues.name" />
              <div class="form-control-feedback ">{{ errors.name }}</div>
            </div>
          </div>

          
          <div class="col-lg-12">
            <div class="form-group add-aditnoal" :class="{ 'has-error': errors.product }"
              v-if="formValues.product != 0">
              <label for="product">Product<span class="required-field text-danger">*</span></label>
              <Field v-model="formValues.product" name="product">
                <Multiselect v-model="formValues.product" label="name" valueProp="id" trackBy="name" :searchable="true"
                  :options="[{ id: 0, name: '+ Add new product' }, ...products]" placeholder="Select Product" />
              </Field>
              <div class="form-control-feedback">{{ errors.product }}</div>
            </div>
          </div>
          <div class="col-lg-12">
            <div v-show="formValues.product == 0">
              <div class="d-flex align-item-center justify-content-between mb-3">
                <h6 class="m-0 offer-header">New Product Details</h6>
                <span class="offer-close" @click="formValues.product = null">
                  <CrossIcon />
                </span>
              </div>

              <Form :validation-schema="product_schema" v-slot="{ errors }" class="form-horizontal offer-form"
                action="#" ref="productForm">
                <div class="modal-body offer-body-modal">
                  <div class="form-group" :class="{ 'has-error': errors.number }">
                    <label for="number">Product Number<span class="required-field text-danger">*</span></label>
                    <Field as="input" type="text" class="form-control" name="number"
                      placeholder="Enter product unique number" v-model="product_formvalues.number" />
                    <div class="form-control-feedback">{{ errors.number }}</div>
                  </div>
                  <div class="form-group" :class="{ 'has-error': errors.name }">
                    <label for="name">Product name<span class="required-field text-danger">*</span></label>
                    <Field as="input" type="text" class="form-control" name="name" placeholder="Enter product name"
                      v-model="product_formvalues.name" />
                    <div class="form-control-feedback">{{ errors.name }}</div>
                  </div>

                  <div class="form-group" :class="{ 'has-error': errors.category }" v-if="product_formvalues.category !== 0">
                    <label for="category">category</label>
                    <!-- add functionality for creating a new category -->

                    <Field v-model="formValues.category" name="category">
                      <Multiselect v-model="product_formvalues.category" label="name" valueProp="id" trackBy="name"
                        :searchable="true" 
                        :options="[{ id: 0, name: '+ Add new category' },...productCategories]"
                        placeholder="Select product category" />
                    </Field>
                    <div class="form-control-feedback">{{ errors.category }}</div>
                  </div>

                  <div v-else>
              <div
                class="d-flex align-item-center justify-content-between mb-3"
              >
                <h6 class="m-0 offer-header">New Category Details</h6>
                <span class="offer-close" @click="clearCategoryForm">
                  <CrossIcon />
                </span>
              </div>
                <div class="modal-body offer-body-modal">
                  <div class="form-group" :class="{ 'has-error': errors.category_name }">
                    <label for="category_name"
                      >Category Name</label>
                    <Field
                      as="input"
                      type="text"
                      class="form-control"
                      name="category_name"
                      placeholder="Enter Category Name"
                      v-model="formValues.category_name"
                    />
                    <div class="form-control-feedback">{{ errors.category_name }}</div>
                  </div>
                </div>
      </div>
                  <div class="form-group" :class="{ 'has-error': errors.detail }">
                    <Field as="input" type="hidden" class="form-control" name="detail" />
                    <div class="form-control-feedback">{{ errors.detail }}</div>
                  </div>
                </div>
                <button type="submit" ref="submit" class="save-btn" v-show="false">
                  create
                </button>
              </Form>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group" :class="{ 'has-error': errors.merchant_price }">
              <label for="merchant_price">Merchant Cost Price<span class="required-field text-danger">*</span></label>
              <Field as="input" type="number" step="0.01" class="form-control" name="merchant_price"
                placeholder="Enter cost price" v-model="formValues.merchant_price" />
              <div class="form-control-feedback">{{ errors.merchant_price }}</div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group" :class="{ 'has-error': errors.currency }">
              <label for="amount">currency<span class="required-field text-danger">*</span></label>
              <Field v-model="formValues.currency" name="currency">
                <Multiselect :id="'-currency'" v-model="formValues.currency"
                  :options="removeDuplicatesAndBlank(['SEK', 'USD', 'EUR', 'GBP', (formValues?.currency || '')])"
                  placeholder="Select Currency" />
              </Field>
              <div class="form-control-feedback">{{ errors.currency }}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="add-cutomers-file-popup">
        <div class="info-line alert alert-success" role="alert">
          <img src="@/../public/assets/images/sms.svg" alt="Logo" />
          <p>You can send sales channel Articles by upload them in MS Excel format
            (.xlxs). This will update the existed Articles, and add newly sold.
            Template for the Excel structure:
            <a href="/assets/files/Example Article File.xlsx" download>
              <ul>
                click here
              </ul>
            </a>
          </p>
        </div>
        <div class="file-drop-box">
          <div class="file-drop-upload">
            <input type="file" id="file-upload" class="form-control d-none" @change="onFileChnage"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
            <div class="file-drop-upload-info">
              <img src="@/../public/assets/images/upload-icon.svg" alt="Logo" />
              <h6>Drag & drop files or
                <label for="file-upload" role="button">
                  Select File</label>
              </h6>
              <p>Supported formats: Excel</p>
              <span>{{ articleFile?.name }}</span>
              <div class="form-control-feedback">
                {{ articleFileErrMsg }}
              </div>
            </div>
          </div>

          <!-- <div>
                    <a href="../../../public/assets/files/sample.xlsx" download>sample file</a>
                  </div> -->
        </div>
      </div>
      <div class="form-group" :class="{ 'has-error': errors.detail }">
        <Field as="input" type="hidden" class="form-control" name="detail" />
        <div class="form-control-feedback">{{ errors.detail }}</div>
      </div>
      <!--end form-group-->
      <span as="button" class="d-flex justify-content-center file-upload-title" v-if="!isFileUploadMode && !isEditMode"
        @click="isFileUploadMode = true"><img src="@/../public/assets/images/import-icon.svg" alt="Logo" /> Import From
        File</span>
      <span as="button" class="d-flex justify-content-center file-upload-title" v-if="isFileUploadMode"
        @click="isFileUploadMode = false"><i class="bi bi-file-text"></i>Add manually</span>
    </div>
    <div class="modal-footer">
      <button type="button" class="cancle-btn" data-bs-dismiss="modal"  id="close-import-articles-model">
        Cancel
      </button>

      <button type="submit" class="save-btn" v-if="isFileUploadMode" @click="HandleArticleFileUpload">
        <div class="spinner-border text-light" role="status" style="width: 14px; height: 14px;" v-if="isLoading">
          <span class="visually-hidden">Loading...</span>
        </div>
        <span v-else>Add File</span>
      </button>

      <button v-else type="submit" class="save-btn">
        {{ isEditMode ? "Save" : "Create User" }}
      </button>

    </div>
  </Form><!--end form-->
</template>

<script>
import { Field, Form } from "vee-validate";
import Multiselect from "@vueform/multiselect";
import { mapActions, mapGetters } from "vuex";
import {
  LIST_ARTICLES,
  LIST_PRODUCTS,
  LIST_PRODUCT_CATEGORIES
} from "@/Core/store/action-types";
import CrossIcon from "../../icons/cross.vue";
// import {PHONE_REGEX} from "../../Core/helpers/utils"
import * as yup from "yup";
export default {
  name: "ArticleForm",
  components: {
    Form,
    Multiselect,
    Field,
    CrossIcon
  },
  props: {
    isEditMode: {
      required: false,
      default: false,
    },
    onSubmit: {
      required: true,
    },
    initData: {
      required: false,
    },
    onFileUpload: {
      required: false,
    },
  },
  data() {
    return {
      formValues: {},
      product_formvalues: {},
      productCategories: [],
      articleFile: null,
      articleFileErrMsg: "",
      isLoading: false,
      isFileUploadMode: false,
      products: [],

      // schema for articles data
      schema: yup.object().shape({
        name: yup.string().required("please enter article name").nullable(true, "please enter article name"),
        sku: yup.string().required("please enter article unique number").nullable(true, "please enter article unique number"),
        product: yup.number().notRequired(), 
        currency: yup.string().required("please select currency").nullable(true, "please select currency"),
        merchant_price: yup.number().typeError('Please enter valid merchant price').required("Please enter merchant price").nullable(true, "Please enter merchant price"),
        category: yup
          .number()
          .nullable().notRequired(),
        detail: yup
          .string() // use it for backend errors
          .nullable()
          .notRequired(),
      }),

      // product schema
      product_schema: yup.object().shape({
        name: yup.string().required("please enter product name").nullable(true, "please enter product name"),
        number: yup.string().required("please enter product unique number").nullable(true, "please enter product unique number"),
        category: yup.number().notRequired(),
        category_name: yup.string().nullable().notRequired(),
        detail: yup
          .string() // use it for backend errors
          .nullable()
          .notRequired(),
      }),
    };
  },
  computed: {
    ...mapGetters("org", ["org", "default_currency"]),
  },
  methods: {
    ...mapActions('product', [LIST_PRODUCTS, LIST_PRODUCT_CATEGORIES]),

    ...mapActions("article", [LIST_ARTICLES]),
    initArticles(pageNumber = null) {
      let params = {
        page: pageNumber
      };

      this[LIST_ARTICLES]({ persist: true, params }).then((res) => {
        this.articles = res;
        console.log(":", res); // Log the entire data object
      })
    },
    

    fetchProducts() {
      let params = {}
      this[LIST_PRODUCTS](params).then((res) => {
        this.products = res

      })
    },
    removeDuplicatesAndBlank(array) {
      const uniqueValues = array.filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
      return uniqueValues.filter(value => value !== '');
    },
    fetchProductCategories() {
      let params = {}
      this[LIST_PRODUCT_CATEGORIES](params).then((res) => {
        this.productCategories = res
      })
    },
    onFileChnage(e) {
      this.articleFile = e.target.files[0];
    },
    HandleArticleFileUpload() {
      this.isLoading = true
      if (this.articleFile) {
        console.log('if')
        this.onFileUpload(this.articleFile)
      } else {
        console.log('else')
        this.articleFileErrMsg = "Please select file";
      }
      this.isLoading = false
    },
    clearCategoryForm() {
      this.formValues.category = null;
      this.product_formvalues.category = null
    }
  },
  emits: ["form-change"],

  watch: {
    formValues: {
      handler: function (newForm) {
        this.$emit("form-change", newForm);
      },
      deep: true,
    },

    initData: function (newArticle) {
      this.formValues = {
        name: newArticle.name,
        number: newArticle.sku,
        product: newArticle?.product?.id,
        merchant_price: newArticle?.article_merchant?.store_price?.toFixed(0) || 0,
        currency: newArticle?.article_merchant?.currency || this.default_currency,
        category:newArticle?.category?.id,
        category_name:null
      };
    },
  },
  created() {
    console.log('?', this.initData)

  },
  mounted() {
    this.initArticles()
    this.fetchProducts()
    this.fetchProductCategories()
  },

}
</script>
<style scoped>
.file-upload-title {
  color: #565DF2;
  background: rgba(86, 93, 242, 0.1019607843);
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  gap: 10px;
  padding: 13px;
}
</style>