<template>
    <LineChart :chartData="chartData" :chartOptions="chartOptions" class="canvas-outer" />
</template>

<script>
import { defineComponent } from "vue";
import { Chart, registerables } from "chart.js";
import { LineChart } from "vue-chart-3";

Chart.register(...registerables);

export default defineComponent({
    components: {
        LineChart,
    },
    props: {
        chartData: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            chartOptions: {
                responsive: true,
                plugins: {
                    legend: {
                        position: "bottom",
                    },
                },
                scales: {
                    x: {
                        stacked: false,
                    },
                    y: {
                        stacked: false,
                    },
                },
            },
        };
    },
});
</script>