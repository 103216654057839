<template>
  <div>
    <div class="vld-parent">
      <loading v-model:active="isLoading" :is-full-page="fullPage" />
    </div>
    <TopBar title="GraphAnalytics" :author="{ home: 'Home', cart: 'GraphAnalytics' }" />
    <MainMenu />
    <div class="main-wrp">
      <div class="parent">
        <div class="gp-chart border-wrp">
          <h5 class="graph-heading">Customer Recommendations</h5>
          <GroupedChart :chartData="customerRecommendationChartData" />
        </div>

        <div class="gp-chart border-wrp">
          <h5 class="graph-heading">Estimated Sales</h5>
          <GroupedChart :chartData="salesVolumeChartData" />
        </div>

        <div class="gp-chart border-wrp">
          <h5 class="graph-heading">Estimated Volume</h5>
          <GroupedChart :chartData="restockItemsChartData" />
        </div>

        <div class="div4 border-wrp">
          <h5 class="graph-heading">Sale</h5>
          <LineChart :chartData="saleschartData" />
        </div>

        <div class="div5 border-wrp">
          <h5 class="graph-heading">Volume</h5>
          <GroupedChart :chartData="ordersQuantityChartData" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopBar from "../Menu/TopBar.vue";
import "vue-loading-overlay/dist/vue-loading.css";
import Loading from "vue-loading-overlay";
import MainMenu from "../Menu/MainMenu.vue";
import LineChart from "./components/LineChart.vue";
import { mapActions } from "vuex";
import { GET_RECOMMENDATIONS_STATS } from "@/Core/store/action-types";
import GroupedChart from "./components/GroupedChart.vue";

export default {
  name: "GraphAnalytics",
  components: {
    Loading,
    TopBar,
    MainMenu,
    LineChart,
    GroupedChart
  },
  data() {
    return {
      recommendationsStats: {},
      fullPage: true,
      isLoading: false,
      sortedMonths: this.getMonthsTillNow(),
    };
  },
  methods: {
    ...mapActions("org", [GET_RECOMMENDATIONS_STATS]),

    async initRecommendations() {
      this.isLoading = true;
      const userProfile = JSON.parse(localStorage.getItem('userProfile'));
      const org_id = userProfile.organization.id;

      await this.$store.dispatch('org/GET_RECOMMENDATIONS_STATS', org_id)
        .then(data => {
          this.recommendationsStats = data;
          this.isLoading = false;
        })
        .catch(error => {
          console.error('Failed to fetch recommendations stats:', error);
          this.isLoading = false;
        });
    },

    // Utility function to create chart datasets
    createChartDatasets(firstDataset, secondDataset, firstLabel, secondLabel) {
      return {
        labels: this.getMonthLabels(),
        datasets: [
          {
            label: firstLabel,
            data: firstDataset,
            backgroundColor: "#565DF2",
            borderColor: "#565DF2",
            fill: false,
            tension: 0.4,
          },
          {
            label: secondLabel,
            data: secondDataset,
            backgroundColor: "#30BF80",
            borderColor: "#30BF80",
            fill: false,
            tension: 0.4,
            tooltip: {
              callbacks: {
                label: function (context) {
                  let label = context.dataset.label || "";
                  let value = context.raw;
                  let formattedValue = value.toLocaleString("en-US");

                  if (context.datasetIndex === 1) {
                    let firstDatasetValue = context.chart.data.datasets[0].data[context.dataIndex];
                    let percentage = firstDatasetValue ? ((value / firstDatasetValue) * 100).toFixed(2) : "N/A";

                    return `${label}: ${formattedValue} (${percentage}%)`;
                  } else {
                    return `${label}: ${formattedValue}`;
                  }
                },
              },
            },
          },
        ],
      };
    },
    getMonthsTillNow() {
      const months = [
        'january', 'february', 'march', 'april', 'may', 'june',
        'july', 'august', 'september', 'october', 'november', 'december'
      ];

      const currentMonthIndex = new Date().getMonth(); // Get current month index (0-based)

      return months.slice(0, currentMonthIndex + 2); // Return months from January till upcoming month
    },

    // Return monthly labels consistently
    getMonthLabels() {
      const thisYear = this.recommendationsStats?.this_year_projections || {};
      return this.sortedMonths
        .filter(month => thisYear[month])
        .map(month => thisYear[month].month);
    },

    // Extract data from projection objects using a consistent method
    getDataFromProj(projectionObj, dataKey) {
      if (!projectionObj) return [];

      return this.sortedMonths
        .filter(month => projectionObj[month])
        .map(month => projectionObj[month][dataKey] || 0);
    },
    // for the sales graph this year data should be till the month and last year should be entire
    getSalesChartMonths(monthIns, projectionObj, dataKey) {
      if (!projectionObj) return [];

      return monthIns.filter(month => projectionObj[month])
        .map(month => projectionObj[month][dataKey] || 0);
    },

  },
  computed: {
    thisYearProj() {
      return this.recommendationsStats?.this_year_projections || {};
    },

    lastYearProj() {
      return this.recommendationsStats?.last_year_projections || {};
    },

    saleschartData() {
      const entireYear = [
        'january', 'february', 'march', 'april', 'may', 'june',
        'july', 'august', 'september', 'october', 'november', 'december']

      const currentMonthIndex = new Date().getMonth();
      const monthUpTill = entireYear.slice(0, currentMonthIndex + 1);

      return {
        labels: entireYear,
        datasets: [
          {
            label: 'Last Year Sales',
            data: this.getSalesChartMonths(entireYear, this.lastYearProj, 'actual_order_amount'),
            backgroundColor: "#565DF2",
            borderColor: "#565DF2",
            fill: false,
            tension: 0.4,
          },
          {
            label: 'This Year Sales',
            data: this.getSalesChartMonths(monthUpTill, this.thisYearProj, 'actual_order_amount'),
            backgroundColor: "#30BF80",
            borderColor: "#30BF80",
            fill: false,
            tension: 0.4,
            tooltip: {
              callbacks: {
                label: function (context) {
                  let label = context.dataset.label || "";
                  let value = context.raw;
                  let formattedValue = value.toLocaleString("en-US");

                  if (context.datasetIndex === 1) {
                    let firstDatasetValue = context.chart.data.datasets[0].data[context.dataIndex];
                    let percentage = firstDatasetValue ? ((value / firstDatasetValue) * 100).toFixed(2) : "N/A";

                    return `${label}: ${formattedValue} (${percentage}%)`;
                  } else {
                    return `${label}: ${formattedValue}`;
                  }
                },
              },
            },
          },
        ],
      };
    },

    ordersQuantityChartData() {
      if (!this.recommendationsStats) {
        return { labels: [], datasets: [] };
      }

      const thisYearData = this.getDataFromProj(this.thisYearProj, 'actual_order_qty');
      const lastYearData = this.getDataFromProj(this.lastYearProj, 'actual_order_qty');

      return this.createChartDatasets(
        lastYearData,
        thisYearData,
        "Last Year Orders",
        "This Year Orders"
      );
    },

    customerRecommendationChartData() {
      if (!this.recommendationsStats) {
        return { labels: [], datasets: [] };
      }

      const customerRecommendations = this.getDataFromProj(
        this.thisYearProj,
        'customer_recommendation_count'
      );

      const customerPurchases = this.getDataFromProj(
        this.thisYearProj,
        'customer_who_made_purchases_by_reommdation'
      );

      return this.createChartDatasets(
        customerRecommendations,
        customerPurchases,
        "Customer Restock",
        "Purchases Made"
      );
    },

    salesVolumeChartData() {
      if (!this.recommendationsStats) {
        return { labels: [], datasets: [] };
      }

      const possibleSalesVolume = this.getDataFromProj(
        this.thisYearProj,
        'possible_total_sales_amount'
      );

      const actualSalesVolume = this.getDataFromProj(
        this.thisYearProj,
        'actual_order_amount_of_recommendation'
      );

      return this.createChartDatasets(
        possibleSalesVolume,
        actualSalesVolume,
        "Estimated Sales Volume",
        "Actual Sales Volume"
      );
    },

    restockItemsChartData() {
      if (!this.recommendationsStats) {
        return { labels: [], datasets: [] };
      }

      const restockItems = this.getDataFromProj(
        this.thisYearProj,
        'possible_total_sales_count'
      );

      const purchasedItems = this.getDataFromProj(
        this.thisYearProj,
        'actual_order_qty_of_recommendation'
      );

      return this.createChartDatasets(
        restockItems,
        purchasedItems,
        "Restock Items",
        "Purchased Items"
      );
    },
  },
  async mounted() {
    await this.initRecommendations();
  },
};
</script>

<style>
.border-wrp {
  background: #fff;
  border: 1px solid #ECECED;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  padding: 15px;
  border-radius: 12px;
}

.gp-chart {
  width: 100%;
}

.parent {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 10px;
}

.div4 {
  grid-column: span 2 / span 2;
  grid-row-start: 2;
}

.div5 {
  grid-column-start: 3;
  grid-row-start: 2;
}

.graph-heading {
  margin: 5px 0px 30px 5px;
}
</style>